import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useDownloadExcel } from 'react-export-table-to-excel';
import _ from 'lodash';
import {
  IApiListResult,
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  IMatchMenuIdPageId,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import * as cmnApi from '../apis/cmn.api';
import moment from 'moment';
import { PbButton } from '../components/PbButton';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import PbSection from '../components/PbSection/PbSection.component';
import { PbDatePicker } from '../components/PbDatePicker';
import { PbInputText } from '../components/PbInputText';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  componentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import { MN2302220805_CODE } from '../components/MN2302220805_CODE';
import { MN2302220805_DETAILCODE } from '../components/MN2302220805_DETAILCODE';
import { nanoid } from 'nanoid';
import { Button, Select, TextInput, Radio, Group } from '@mantine/core';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { AgGridReact } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';
import { PbAgGridReact } from '../components/PbAgGridReact';
import PbSelect from '../components/PbSelect/PbSelect.component';
import { decode } from 'html-entities';

/**
 * 시스템 관리 > 코드 관리
 * @constructor .
 */
const MN2302220801Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * 검색 구분 / 검색어 입력
   */

  // 검색 폼 그리드 > 검색어 > 검색구분 셀렉트를 정의함
  const [searchCategorySelect, setSearchCategorySelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCategorySelect_onChange = (event: any) => {
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
  const handleSearchButton_onChange = () => {
    setCommonDetailCodeTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: [],
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getCommonCodeTableData();
  };

  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onChange = () => {
    // 검색 폼 그리드 > 검색어 > 셀렉트 값을 초기화함
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: 'grp_code_id',
    }));

    // 검색 폼 그리드 > 검색어 > 검색어 입력 값을 초기화함
    setSearchInput('');

    // 상세 검색 > 사용 여부 셀렉트의 아이템을 초기화함
    setUseYnSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initSearchCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '코드',
        value: 'grp_code_id',
      },
      {
        label: '코드명칭',
        value: 'grp_code_dscr',
      },
    );

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setSearchCategorySelect((pre: ISelect) => ({
      value: 'grp_code_id',
      item: tmpOptionItem,
    }));
  };

  /* // 검색 구분 / 검색어 입력 */

  /**
   * 사용여부
   */

  // 상세 검색 > 사용 여부 셀렉트를 정의함
  const [useYnSelect, setUseYnSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 상세 검색 > 사용 여부 셀렉트에서 선택한 아이템을 변경함
  const handleUseYnSelect_onChange = (event: any) => {
    setUseYnSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 상세 검색 > 사용 여부 셀렉트의 아이템을 초기화함
  const initUseYnSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '사용',
        value: 'Y',
      },
      {
        label: '미사용',
        value: 'N',
      },
    );

    // 상세 검색 > 사용 여부 셀렉트의 아이템에 적용함
    setUseYnSelect({
      value: '',
      item: tmpOptionItem,
    });
  };

  /* //사용여부 */

  /**
   * 검색 / 검색 조건 초기화 / 상세검색 열기 / 상세검색 닫기
   */

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  /* // 검색 / 검색 조건 초기화 / 상세검색 열기 / 상세검색 닫기 */

  /**
   * 공통코드 관리 테이블
   */

  // 공통코드 데이터를 불러옴
  const getCommonCodeTableData = () => {
    // 공통코드 테이블의 로딩 여부에 적용함
    setCommonCodeTableLoading(true);

    // 공통상세코드 테이블의 로딩 여부에 적용함
    setCommonDetailCodeTableLoading(true);

    cmnApi
      .getCodes({
        pageSize: 1000, // 페이지 사이즈
        currPageIdx: '1', // 현재 페이지
        searchType: searchCategorySelect.value, // 검색 구분
        searchKeyword: searchInput.trim(), // 검색어
        useYn: useYnSelect.value, // 사용여부
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setCommonCodeTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));
        }

        // 공통코드 테이블의 로딩 여부에 적용함
        setCommonCodeTableLoading(false);

        // 공통상세코드 테이블의 로딩 여부에 적용함
        setCommonDetailCodeTableLoading(false);
      });
  };

  // 공통상세코드 데이터를 불러옴
  const getCommonDetailCodeTableData = (pGrpCodeId: string) => {
    cmnApi
      .getDetailCodesAll({
        grpCodeId: pGrpCodeId, // 상위 공통코드
        containUnused: true,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          let result = data.data.list;
          result = result
            .sort((a: any, b: any) => Number(a.sortRdr) - Number(b.sortRdr))
            .sort(
              (a: any, b: any) =>
                a.dtlCodeId.toLowerCase() < b.dtlCodeId.toLowerCase(),
            );

          // 검색 결과 테이블에 적용함
          setCommonDetailCodeTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: result,
            }),
          );
        }
      });
    // cmnApi
    //   .getDetailCodes({
    //     pageSize: 1000, // 페이지 사이즈
    //     currPageIdx: '1', // 현재 페이지
    //     grpCodeId: pGrpCodeId, // 상위 공통코드
    //   })
    //   .then((data: IApiResult) => {
    //     if (data.data.list !== undefined) {
    //       var result = data.data.list;
    //       result = result
    //         .sort((a: any, b: any) => Number(a.sortRdr) - Number(b.sortRdr))
    //         .sort(
    //           (a: any, b: any) =>
    //             a.dtlCodeId.toLowerCase() < b.dtlCodeId.toLowerCase(),
    //         );
    //
    //       // 검색 결과 테이블에 적용함
    //       setCommonDetailCodeTable(
    //         (pre: { column: ITableColumn[]; data: any }) => ({
    //           ...pre,
    //           data: result,
    //         }),
    //       );
    //     }
    //   });
  };

  // 공통코드 테이블의 행을 클릭함
  const handleCommonCodeTable_onRowDoubleClicked = (row: any) => {
    setSelectedGrpCodeId(row.data.grpCodeId); // 상세코드를 위한 공통코드 설정
    getCommonDetailCodeTableData(row.data.grpCodeId);
  };

  // 공통코드 테이블의 행을 더블클릭함
  const handleCommonCodeTable_onDoubleClickRow = (params: any) => {
    let tmpId: string = 'MN2302220805_CODE';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '공통코드',
      content: (
        <MN2302220805_CODE
          id={tmpId}
          grpCodeId={params.data.grpCodeId}
          useModifyMode={false}
        />
      ),
      size: 1100,
    });
  };

  // 공통 상세코드 등록을 위한 상위 코드 설정
  const [selectedGrpCodeId, setSelectedGrpCodeId] = useState<string>('');

  // 공통코드 테이블을 정의함
  const [commonCodeTable, setCommonCodeTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 공통코드 테이블의 로딩 여부를 정의함
  const [commonCodeTableLoading, setCommonCodeTableLoading] =
    useState<boolean>(false);

  // 공통코드 테이블을 초기화함
  const initCommonCodeTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'grpCodeId',
        headerName: '코드 / 코드명',
        cellRenderer: (params: any) => (
          <div className="h-12 flex justify-start items-center">
            <div className="w-full space-y-1">
              {/* 코드 */}
              <div className="leading-none truncate">
                <span className="">{params.data.grpCodeId}</span>
              </div>

              {/* 코드명 */}
              <div className="flex justify-start items-center">
                <div
                  style={{ padding: '2px 5px' }}
                  className="leading-none truncate bg-gray-500 rounded-sm"
                >
                  <span className="text-sm text-white">
                    {params.data.grpCodeDscr}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      // {
      //   field: 'grpCodeDscr',
      //   headerName: '코드명칭',
      // },
      {
        field: 'grpCodeSrc',
        headerName: '코드설명',
      },
      {
        field: 'useYn',
        headerName: '사용여부',
        // valueFormatter: (
        //   (params: ValueFormatterParams) => {
        //     if (params.data.useYn === 'Y') {
        //       return '사용';
        //     } else {
        //       return '미사용';
        //     }
        //   }
        // )
        width: 90,
        suppressSizeToFit: true,
      },
      // {
      //   field: 'rgstName',
      //   headerName: '등록자',
      //   width: 100,
      //   suppressSizeToFit: true,
      //   valueFormatter: (params: ValueFormatterParams) => {
      //     if (params.value === null || params.value === '') {
      //       return '시스템';
      //     } else {
      //       return params.value;
      //     }
      //   },
      // },
      // {
      //   field: 'rgstDate',
      //   headerName: '등록일',
      //   width: 110,
      //   suppressSizeToFit: true,
      // },
      {
        headerName: '',
        field: '',
        width: 80,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="w-full col-span-3 space-y-3">
            <div className="space-y-3">
              <Button
                color="indigo"
                radius="xl"
                onClick={() => handleCommonCodeTable_onDoubleClickRow(params)}
              >
                수정
              </Button>
            </div>
          </div>
        ),
      },
    );

    // 공통코드 테이블에 적용함
    setCommonCodeTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 공통상세코드 테이블을 정의함
  const [commonDetailCodeTable, setCommonDetailCodeTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 공통상세코드 테이블의 로딩 여부를 정의함
  const [commonDetailCodeTableLoading, setCommonDetailCodeTableLoading] =
    useState<boolean>(false);

  // 공통상세코드 테이블의 행을 클릭함
  const handleCommonDetailCodeTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220805_DETAILCODE';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '공통상세코드',
      content: (
        <MN2302220805_DETAILCODE
          id={tmpId}
          grpCodeId={row.data.grpCodeId}
          dtlCodeId={row.data.dtlCodeId}
          useModifyMode={false}
        />
      ),
      size: 1100,
    });
  };

  // 공통상세코드 테이블을 초기화함
  const initCommonDetailCodeTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'dtlCodeId',
        headerName: '상세코드 / 코드명',
        cellRenderer: (params: any) => (
          <div className="h-12 flex justify-start items-center">
            <div className="w-full space-y-1">
              {/* 코드 */}
              <div className="leading-none truncate">
                <span className="">{params.data.dtlCodeId}</span>
              </div>

              {/* 코드명 */}
              <div className="flex justify-start items-center">
                <div
                  style={{ padding: '2px 5px' }}
                  className="leading-none truncate bg-gray-500 rounded-sm"
                >
                  <span className="text-sm text-white">
                    {params.data.dtlCodeDscr}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      // {
      //   field: 'dtlCodeDscr',
      //   headerName: '코드명칭',
      // },
      {
        field: 'sortRdr',
        headerName: '순서',
        width: 70,
        suppressSizeToFit: true,
        type: 'rightAligned',
      },
      {
        field: 'dtlCodeSrc',
        headerName: '설명',
      },
      {
        field: 'useYn',
        headerName: '사용여부',
        // valueFormatter: (params: ValueFormatterParams) => {
        //   if (params.data.useYn === 'Y') {
        //     return '사용';
        //   } else {
        //     return '미사용';
        //   }
        // },
        width: 90,
        suppressSizeToFit: true,
      },
      // {
      //   field: 'rgstName',
      //   headerName: '등록자',
      //   width: 100,
      //   suppressSizeToFit: true,
      //   valueFormatter: (params: ValueFormatterParams) => {
      //     if (params.value === null || params.value === '') {
      //       return '시스템';
      //     } else {
      //       return params.value;
      //     }
      //   },
      // },
      // {
      //   field: 'rgstDate',
      //   headerName: '등록일',
      //   width: 110,
      //   suppressSizeToFit: true,
      // },
      // {
      //   field: 'editName',
      //   headerName: '수정자',
      // },
      // {
      //   field: 'mdfcDate',
      //   headerName: '수정일',
      // },
    );

    // 검색 결과 테이블에 적용함
    setCommonDetailCodeTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /* // 공통코드 관리 테이블  */

  // 공통코드 추가
  const handleCommonCodePostButton_onClick = () => {
    let tmpId: string = 'MN2302220805_CODE';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '공통코드 등록',
      content: <MN2302220805_CODE id={tmpId} />,
      size: 1100,
    });
  };

  // 공통코드 추가
  const handleCommonDetailCodePostButton_onClick = () => {
    if (_.isEmpty(selectedGrpCodeId)) {
      setModal({
        title: '알림',
        content: '공통코드를 선택하세요.',
      });
      return;
    } else {
      let tmpId: string = 'MN2302220805_DETAILCODE';

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '공통상세코드 등록',
        content: (
          <MN2302220805_DETAILCODE id={tmpId} grpCodeId={selectedGrpCodeId} />
        ),
        size: 1100,
      });
    }
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    //initial states
    // 검색 폼 그리드 > 검색어 셀렉트의 아이템을 초기화함
    initSearchCategorySelect();

    // 검색 폼 그리드 > 사용여부 셀렉트의 아이템을 초기화함
    initUseYnSelect();

    // 공통코드 테이블 초기화
    initCommonCodeTable();

    // 공통상세코드 목록 테이블 초기화
    initCommonDetailCodeTable();

    return () => {};
  }, []);

  // 검색 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (commonCodeTable.column.length === 0) {
      return;
    }

    // 검색 결과 테이블 데이터를 불러옴
    getCommonCodeTableData();

    return () => {};
  }, [commonCodeTable.column]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220805Table') > -1) {
      // 공통상세코드 목록 테이블 초기화
      setCommonDetailCodeTable(
        (pre: { column: ITableColumn[]; data: any }) => ({
          ...pre,
          data: [],
        }),
      );

      // 검색 결과 테이블 데이터를 불러옴
      getCommonCodeTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220805Table');
    }

    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-10">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid cols={2}>
            <PbFormGridCol label="검색어" colSpan={2}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchCategorySelect_onChange}
                data={searchCategorySelect.item}
                value={searchCategorySelect.value}
                setSelect={setSearchCategorySelect}
              />

              {/* 텍스트 입력 */}
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={handleSearchInput_onChange}
                value={searchInput}
                className="w-1/5"
              />

              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleSearchButton_onChange}
                >
                  검색
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchCancelButton_onChange}
                >
                  검색 조건 초기화
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>
            {/* 사용여부 폼 그리드 */}
            <PbFormGridCol
              label="사용 여부"
              colSpan={2}
              isHidden={hideDetailSearch}
            >
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleUseYnSelect_onChange}
                data={useYnSelect.item}
                value={useYnSelect.value}
                setSelect={setUseYnSelect}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블 */}

          <div className="space-y-12">
            <PbSection>
              <div className="flex justify-center items-start space-x-5">
                {/* 왼쪽 영역 */}
                <div className="relative w-1/2 h-80">
                  <div className="flex justify-between items-center space-x-5 p-1">
                    <div>
                      <span className="text-base text-gray-600 font-bold">
                        공통 코드
                      </span>
                    </div>

                    <div className="flex justify-center items-center space-x-5">
                      <div className="flex justify-center items-center">
                        {/* 버튼 */}
                        <Button
                          variant="outline"
                          color="gray"
                          radius="xl"
                          onClick={handleCommonCodePostButton_onClick}
                        >
                          코드추가
                        </Button>
                      </div>
                    </div>
                  </div>

                  {/* 테이블 */}
                  <div className="w-full h-152">
                    <PbAgGridReact
                      columnDefs={commonCodeTable.column}
                      rowData={commonCodeTable.data}
                      loading={commonCodeTableLoading}
                      setLoading={setCommonCodeTableLoading}
                      onRowDoubleClicked={
                        handleCommonCodeTable_onRowDoubleClicked
                      }
                      defaultColDef={{
                        resizable: true,
                        sortable: true,
                        wrapHeaderText: false,
                        autoHeaderHeight: true,
                      }}
                      rowSelection="single"
                      sizeColumnsToFit={true}
                    />
                  </div>

                  {/*/!* 테이블 *!/*/}
                  {/*<div className="w-full h-152">*/}
                  {/*  <AgGridReact*/}
                  {/*    columnDefs={commonCodeTable.column}*/}
                  {/*    rowData={commonCodeTable.data}*/}
                  {/*    onRowClicked={handleCommonCodeTable_onRowDoubleClicked}*/}
                  {/*    onRowDoubleClicked={*/}
                  {/*      handleCommonCodeTable_onDoubleClickRow*/}
                  {/*    }*/}
                  {/*    defaultColDef={{*/}
                  {/*      initialWidth: 150,*/}
                  {/*      resizable: true,*/}
                  {/*      sortable: true,*/}
                  {/*      wrapHeaderText: false,*/}
                  {/*      autoHeaderHeight: true,*/}
                  {/*      suppressSizeToFit: true,*/}
                  {/*    }}*/}
                  {/*    rowSelection="single"*/}
                  {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
                  {/*    className="ag-theme-alpine"*/}
                  {/*  ></AgGridReact>*/}
                  {/*</div>*/}
                </div>

                {/* 오른쪽 영역 */}
                <div className="w-1/2">
                  <div className="flex justify-between items-center space-x-5 p-1">
                    <div>
                      <span className="text-base text-gray-600 font-bold">
                        공통 코드 상세
                      </span>
                    </div>

                    <div className="flex justify-center items-center space-x-5">
                      <div className="flex justify-center items-center">
                        {/* 버튼 */}
                        <Button
                          variant="outline"
                          color="gray"
                          radius="xl"
                          onClick={handleCommonDetailCodePostButton_onClick}
                        >
                          코드추가
                        </Button>
                      </div>
                    </div>
                  </div>

                  {/* 테이블 */}
                  <div className="w-full h-152">
                    <PbAgGridReact
                      columnDefs={commonDetailCodeTable.column}
                      rowData={commonDetailCodeTable.data}
                      loading={commonDetailCodeTableLoading}
                      setLoading={setCommonDetailCodeTableLoading}
                      onRowDoubleClicked={
                        handleCommonDetailCodeTable_onRowDoubleClicked
                      }
                      defaultColDef={{
                        resizable: true,
                        sortable: true,
                        wrapHeaderText: false,
                        autoHeaderHeight: true,
                      }}
                      rowSelection="single"
                      sizeColumnsToFit={true}
                    />
                  </div>
                </div>
              </div>
            </PbSection>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220801Page;
